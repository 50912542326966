import React from 'react';
import { Button, Form, Input, Modal, message } from 'antd';
import "../ModalCom/AddressModal.css";
import { post } from '../../services/ApiRouting';

const AddressModal = ({ isAddressModalOpen, addressHandleOk, addressHandleCancel, addressShowModal, setSelectedAddress }) => {
    const profileData = JSON.parse(localStorage.getItem('profile'));

    const handleFinish = async (values) => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('alternative_number', values.alternative_number);
            formDataToSend.append('pincode', values.pincode);
            formDataToSend.append('address', values.address);
            formDataToSend.append('city', values.city);
            formDataToSend.append('state', values.state);
            formDataToSend.append('user_id', profileData.user_id);
            formDataToSend.append('action', "add");
            formDataToSend.append('admin_token', "OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH");
            formDataToSend.append('current_active', "1");

            const response = await post('/address.php', formDataToSend);

            // Assuming response contains the new address with an 'address_id'
            const newAddress = {
                address_id: response.address_id,
                alternative_number: values.alternative_number,
                pincode: values.pincode,
                address: values.address,
                city: values.city,
                state: values.state,
                user_id: profileData.user_id,
                current_active: "1",
                address_id:response.address_id
            };

            console.log("newAddress", newAddress)

            addressHandleCancel(); // Close the modal
            addressHandleOk();
            message.success('Address added successfully');
            setSelectedAddress(newAddress);
        }
        catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <Button type="primary" onClick={addressShowModal}>
                Add Address
            </Button>
            <Modal
                title="Add address"
                visible={isAddressModalOpen}
                onOk={addressHandleOk}
                onCancel={addressHandleCancel}
                width={1000}
                footer={null}
            >
                <div className='profile-modal-main-con'>
                    <div className='address-main-con-form'>
                        <Form layout='vertical' onFinish={handleFinish}>
                            <div className='profile-modal-name-and-surname-con'>
                                <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please enter your address' }]}>
                                    <Input placeholder='Enter Address' />
                                </Form.Item>
                                <Form.Item
                                    name="alternative_number"
                                    label="Alternative Number"
                                    rules={[
                                        { required: true, message: 'Please enter your contact number' },
                                        { pattern: /^[0-9]{10}$/, message: 'Contact number must be 10 digits' }
                                    ]}
                                >
                                    <Input placeholder='Enter Contact No.' maxLength={10} />
                                </Form.Item>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <Form.Item name="city" label="City" rules={[{ required: true, message: 'Please enter your city' }]}>
                                            <Input placeholder='Enter City' />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4'>
                                        <Form.Item name="state" label="State" rules={[{ required: true, message: 'Please enter your state' }]}>
                                            <Input placeholder='Enter State' />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-4'>
                                        <Form.Item
                                            name="pincode"
                                            label="Pincode"
                                            rules={[
                                                { required: true, message: 'Please enter your pincode' },
                                                { pattern: /^[0-9]{6}$/, message: 'Pincode must be 6 digits' }
                                            ]}
                                        >
                                            <Input placeholder='Enter Pincode' maxLength={6} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <Form.Item>
                                <div className='address-model-btn'>
                                    <button type="submit">Submit</button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AddressModal;
