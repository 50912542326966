import React, { useLayoutEffect } from 'react';
import "../Css/Contact.css";
import { Button, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Footer from "../Component/FooterComponent/Footer";
import $ from "jquery";

const Contact = () => {
  const [form] = Form.useForm();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitForm = (values) => {
    console.log("values", values);

    var body = '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#000;padding:20px"><h2 style="color:#fff">1Five</h2><p style="color:#fff">Hello</p><p style="color:#fff">Thank you for your interest in our products/services.</p><p style="color:#fff">Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#fff"><strong>Name:</strong></td><td style="text-align:left;color:#fff">' + values.name + '</td></tr><tr><td style="text-align:left;color:#fff"><strong style="color:#fff">Mail:</strong></td><td style="text-align:left;color:#fff">' + values.email + '</td></tr><tr><td style="text-align:left;color:#fff"><strong>Contact No:</strong></td><td style="text-align:left;color:#fff">' + values.contact + '</td></tr><tr><td style="text-align:left;color:#fff"><strong>Message:</strong></td><td style="text-align:left;color:#fff">' + values.message + '</td></tr></table><p style="text-align:left;color:#fff">Best regards,<br>Your Team at 1Five</p></div></body></html>';


    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "1FiveLuxury@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "1five",
        accountLeadSource: "https://1five.in/",
        accountLeadName: values.name,
        accountLeadEmail: values.email,
        accountLeadPhone: values.contact,
      },
      function (dataa, status) {
        console.log('data:', dataa);
      }
    );

    alert("Your Form has Submitted. Our team will contact you soon.");
    
    // Reset the form fields after submission
    form.resetFields();
  };

  return (
    <>
      <section className='contact-page-banner-section'>
        <div className='contact-page-banner-con'>
          <h2>Contact us</h2>
        </div>
      </section>

      <section className='contact-page-section-2'>
        <div className='contact-page-section-2-form-con'>
          <div className='contact-page-section-2-form'>
            <div className='contact-page-section-2-heading'>
              <h3>Write us a message.</h3>
            </div>
            <div className='contact-page-section-2-main-form'>
              <Form form={form} onFinish={submitForm}>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Please input your name!' },
                  ]}
                >
                  <Input placeholder='Full Name' />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: 'Please input your Email!' },
                  ]}
                >
                  <Input placeholder='Email' />
                </Form.Item>
                <Form.Item
                  name="contact"
                  rules={[
                    { required: true, message: 'Please input your Contact!' },
                  ]}
                >
                  <Input placeholder='Phone Number' />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: 'Please input your Message!' },
                  ]}
                >
                  <TextArea placeholder='Message' rows={6} />
                </Form.Item>
                <Form.Item>
                  <div className='contact-page-sumbit-btn'>
                    <Button htmlType='submit'>
                      Send
                    </Button>
                  </div>
                </Form.Item>
              </Form>
              <p>This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
