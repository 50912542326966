import React, { useState, useEffect } from 'react';
import "./ProductDetails.css";
import { Link, useLocation, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import ProductData from '../ProductData/MenData'; // Adjust the path to where your ProductData is located
import Footer from "../FooterComponent/Footer";
import { useCartContext } from '../../context/addToCart';
import axios from 'axios';
import { get } from '../../services/ApiRouting';
import MenData from '../ProductData/MenData';
import AllData from '../ProductData/AllData';

const ProductDetails = () => {
    const search = useLocation();
    const Path = search.pathname;

    const [product, setProduct] = useState(null);
    const [productPrice, setProductPrice] = useState(0);
    const [productIndex, setProductIndex] = useState(0);
    const [productQuantity, setProductQuantity] = useState(1);


    const {
        handleAddCart,
        selectedSize,
        setSelectedSize,
        selectedColor,
        setSelectedColor,
        setSelectedPrice,
        selectedImages,
        setSelectedImages,
        selectedProduct,
        setSelectedProduct,
    } = useCartContext();

    // useEffect(() => {
    //     const foundSubservice = MenData.find((subservice) => subservice.slug === Path);
    //     if (foundSubservice) {
    //         setProduct(foundSubservice);

    //         // Default settings for the selected product
    //         const defaultColor = foundSubservice.colors[0];
    //         const defaultSize = defaultColor.size[0];
    //         setSelectedProduct(foundSubservice);
    //         setSelectedImages(defaultColor.image);
    //         setSelectedSize(defaultSize);
    //         setSelectedColor(defaultColor);
    //         setSelectedPrice(defaultColor.product_price);
    //         setProductPrice(defaultColor.product_price);
    //     } else {
    //         setProduct(null); // Clear the product if not found
    //     }
    // }, [Path]); // Depend on Path to re-run when it changes


    // const singleProductFetch = async () => {
    //     try {
    //         const response = await get(`/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=1&page=1&slugs=${slug}`);
    //         setProduct(response.list[0]);
    //         const foundProduct = response.list[0]
    //         if (foundProduct) {
    //             const defaultColor = foundProduct.colors[0];
    //             const defaultSize = defaultColor.size[0];
    //             setSelectedProduct(foundProduct);
    //             setSelectedImages(defaultColor.image);
    //             setSelectedSize(defaultSize);
    //             setSelectedColor(defaultColor);
    //             setSelectedPrice(defaultColor.product_price);
    //             setProductPrice(defaultColor.product_price);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // }

    // useEffect(() => {
    //     singleProductFetch()
    // }, [slug]);

    // const handleColorSelection = (colorItem) => {
    //     if (colorItem && colorItem.image && colorItem.size) {
    //         const defaultSize = colorItem.size[0];
    //         setSelectedSize(defaultSize);
    //         setSelectedColor(colorItem);
    //         setSelectedImages(colorItem.image);
    //         setSelectedPrice(colorItem.product_price);
    //         setMainImage(colorItem.image[0].images);
    //         setProductIndex(0);
    //     }
    // };


    useEffect(() => {
        const foundSubservice = AllData.find((subservice) => subservice.slug === Path);
        if (foundSubservice) {
            setProduct(foundSubservice);

            // Default settings for the selected product
            const defaultColor = foundSubservice.colors[0];
            const defaultSize = defaultColor.size[0];
            setSelectedProduct(foundSubservice);
            setSelectedImages(defaultColor.images);
            setSelectedSize(defaultSize);
            setSelectedColor(defaultColor);
            setSelectedPrice(defaultColor.product_price);
            setProductPrice(defaultColor.product_price);
        } else {
            setProduct(null); // Clear the product if not found
        }
    }, [Path]); // Depend on Path to re-run when it changes

    const handleColorSelection = (colorItem) => {
        setSelectedColor(colorItem);
        setSelectedImages(colorItem.images);
        setSelectedSize(colorItem.size[0]); // Select the first size as default
        setProductPrice(colorItem.product_price);
    };

    const handleSizeSelection = (size) => {
        setSelectedSize(size);
    };

    const handleImageChange = (index) => {
        setProductIndex(index);
    };

    if (!product) {
        return <div>Product not found</div>;
    }

    return (
        <>
            <section className='Product-details-section1-main-con'>
                <div className="row">
                    <div className='col-md-6'>
                        <div className='Product-details-Section-1-Images-Main-Con'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className='Product-details-section-1-Side-Images-con'>
                                        {selectedImages && selectedImages.length > 1 &&
                                            selectedImages.map((product_image, index) => (
                                                <div
                                                    key={index}
                                                    className='Product-details-section-1-Side-Image-div'
                                                    onClick={() => handleImageChange(index)}
                                                    style={{
                                                        border: index === productIndex ? '2px solid black' : 'none',
                                                        opacity: index === productIndex ? 1 : 0.6,
                                                        cursor: 'pointer',
                                                        transition: 'all 0.3s ease'
                                                    }}
                                                >
                                                    {product_image && (
                                                        <img src={product_image.images} alt={`Color ${index + 1}`} />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='col-md-9'>
                                    <div className='Product-details-section-1-Side-Main-Image-con'>
                                        <div className='Product-details-section-1-Side-Main-Image'>
                                            {selectedImages && selectedImages[productIndex] && selectedImages[productIndex].images && (
                                                <img src={selectedImages[productIndex].images} alt='Selected Product' />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='Product-Details-Section-1-Details-main-con'>
                            <div className='Product-Details-Section-1-Details-navigation-con'>
                                <div className='Product-Details-Section-1-Details-navigation'>
                                    <Link to={`/category/${product.product_type}`}>
                                        <h2 className='Product-details-navigation'>{product.product_type}</h2>
                                    </Link>
                                    <span> / </span>
                                    <Link to={`/product/${product.slugs}`}>
                                        <h2 className='Product-details-navigation'>{product.product_name}</h2>
                                    </Link>
                                </div>
                                <div className='Product-Details-Section-1-Details-name-price-main-con'>
                                    <div className='Product-Details-Section-1-Details-name'>
                                        <h2>{product.product_name}</h2>
                                    </div>
                                    <div className='Product-Details-Section-1-Details-Price'>
                                        <h4>Rs. {selectedColor && selectedColor.product_price} /-</h4>
                                    </div>
                                </div>
                                <div className='Product-Details-Section-1-Details-color-main-con'>
                                    {product && product.colors.map((colorItem, index) => (

                                        <div
                                            className='Product-Details-Section-1-Details-color'
                                            key={index}
                                            style={{
                                                backgroundColor: colorItem.color_code,
                                                border: selectedColor && selectedColor.color_code === colorItem.color_code ? '5px solid black' : 'none'
                                            }}
                                            onClick={() => handleColorSelection(colorItem)}
                                        />
                                    ))}
                                </div>
                                <div className='Product-Details-Section-1-Details-size-main-con'>
                                    {selectedColor && selectedColor.size.map((sizeItem, index) => (
                                        console.log("sizeItem", sizeItem),
                                        console.log("selectedSize", selectedSize),
                                        <div
                                            className='Product-Details-Section-1-Details-size-con'
                                            key={index}
                                            style={{
                                                border: selectedSize.product_size == sizeItem.product_size ? '1px solid black' : 'none'
                                            }}
                                            onClick={() => handleSizeSelection(sizeItem)}
                                        >
                                            <h4>{sizeItem.product_size}</h4>
                                        </div>
                                    ))}
                                </div>
                                <div className='Product-Details-Section-1-Btn-con'>
                                    <button onClick={() => handleAddCart({
                                        Quantity: productQuantity,
                                        productprice: productPrice,
                                    })}>
                                        Add to Cart
                                    </button>
                                </div>
                                <div className='Product-Details-Section-1-Details-accordion-main-con'>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Description</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='Accordion-Body-content-con'>
                                                    {/* <p>{product.product_desc}</p> */}
                                                    <p>
                                                        <span>Brand : </span> 1Five
                                                    </p>
                                                    <p>
                                                        <span>Article : </span> {product.product_name}
                                                    </p>
                                                    <p>
                                                        <span>Colour : </span> BLACK
                                                    </p>
                                                    <p>
                                                        <span>Code : </span> {product.product_Code}
                                                    </p>
                                                    <p>
                                                        <span>Price : </span> Rs. {selectedColor && selectedColor.product_price} 
                                                    </p>
                                                    <p>
                                                        <span>Size : </span> Ind-xl | uk-xl | usa-L
                                                    </p>
                                                    <p>
                                                        <span>Material : </span> 100% Cotton
                                                    </p>
                                                    <p>
                                                        <span>Care : </span> Machine wash cold , Tumble Dry Low
                                                    </p>
                                                    <p>
                                                        <span>Country Origin : </span> India
                                                    </p>
                                                    <p>
                                                        <span>Manufactured By : </span> 1Five India
                                                    </p>
                                                    
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        {/* <Accordion.Item eventKey="1">
                                            <Accordion.Header>Shipping & Returns</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='Accordion-Body-content-con'>
                                                    <p>We use top carriers such as DHL, FedEx Shipping</p>
                                                    <p>Average delivery time 2 to 10 working days depending on your destination.</p>
                                                    <p>**Deliveries outside of Hong Kong may be subject to import taxes, customs duties, and other fees levied by the destination country. If the order doesn't have duties paid in advance available at checkout, then all import taxes, duties, and customs fees as well as compliance with the laws and regulations of the destination country, are the customer's responsibility.</p>
                                                    <p>**Returns are accepted on most of our physical products if sent back to us within 14 days of your receipt of the ordered merchandise; subject to all tags being intact and not being worn. Please refer to our Return Policy for further instructions on how to return your item or email support@1 Five.com</p>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item> */}
                                    </Accordion>
                                    {/* <div className='Accordion-Body-content-con out-Accordion-Body-content'>
                                        <h2>Multiple Offers</h2>
                                        <p>Discover which offers you qualify for</p>
                                        <p>35% Discount</p>
                                        <p>30% Discount</p>
                                        <p>25% Discount</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ProductDetails;
