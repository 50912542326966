import React, { useEffect, useState } from 'react';
import "../SearchComponent/SearchPage.css";
import { MdSearch } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import ProductCard from '../Card/ProductCard';
import ProductData from '../ProductData/WomenData';
import { Link } from 'react-router-dom';

const SearchPage = ({ toggleSearch, isSearchOpen }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (isSearchOpen) {
            setVisible(true);
        } else {
            setTimeout(() => setVisible(false), 500); // Wait for the slide-up animation to finish
        }
    }, [isSearchOpen]);

    return (
        <>
            {visible && (
                <>
                    <section className={`search-section ${isSearchOpen ? 'show' : 'hide'}`}>
                        <div className='container'>
                            <div className='search-display-info'>
                                <h3>1 result for one</h3>
                            </div>
                            <div className='search-section-main-con'>
                                <div className='search-section-con'>
                                    <div className='search-icons-con'>
                                        <MdSearch />
                                    </div>
                                    <div className='search-bar-main-con'>
                                        <input placeholder='Search' />
                                    </div>
                                    <div className='search-cancel-con' onClick={toggleSearch}>
                                        <RxCross2 />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='search-section-2'>
                            <div className='search-section2-main-con'>
                                <div className='row product-list-row'>
                                    {ProductData.map((product, productIndex) => (
                                        product.color.map((colorItem, colorIndex) => (
                                            <div key={`${productIndex}-${colorIndex}`} className='col-md-3 product-list-col'>
                                                <div className="product-card-main-con">
                                                    <Link to={`${product.slugs}`}>
                                                        <div className="product-card-body-con">
                                                            <div className="product-card-Image-con product-item__image double__image">
                                                                <a>
                                                                    <div className="product-item__bg" style={{ backgroundImage: `url(${colorItem.image[0]?.product_image})` }}></div>
                                                                    {colorItem.image[1] && (
                                                                        <div className="product-item__bg__under" style={{ backgroundImage: `url(${colorItem.image[1].product_image})` }}></div>
                                                                    )}
                                                                </a>
                                                            </div>
                                                            <div className="Product-card-details-con">
                                                                <div className="Product-card-details">
                                                                    <div className="product-card-product-name">
                                                                        <h4>{product.product_Name}</h4>
                                                                    </div>
                                                                    <div className="Product-card-product-review-price">
                                                                        <h6>Rs. {colorItem.product_price} /-</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
};

export default SearchPage;
