import React from 'react';
import "../OrderCom/OrderList.css";


const OrderList = () => {
    return (
        <>

            <section className='order-section-order-list-main-con'>
                <div className='container'>
                    <div className='order-section-order-list-head'>
                        <h2>Orders</h2>
                    </div>
                    <div className='order-section-order-list-con'>
                        <div className='order-section-empty-list-text'>
                            <h2>No orders yet</h2>
                            <h3>Go to store to place an order.</h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderList