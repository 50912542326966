import React, { useEffect, useLayoutEffect, useState } from 'react';
import "../Css/MenProduct.css";
import { HiMenuAlt1 } from "react-icons/hi";
import { Link } from 'react-router-dom';
import Footer from "../Component/FooterComponent/Footer";
import axios from 'axios';
import { Button, Checkbox, Drawer, Collapse, message } from 'antd';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { get } from '../services/ApiRouting';
import { useCartContext } from '../context/addToCart';
import MenData from '../Component/ProductData/MenData';
import { FaStar } from 'react-icons/fa';

const { Panel } = Collapse;

const MenProduct = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    const [menproduct, setMenProduct] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [showSortDropdown, setShowSortDropdown] = useState(false); // State to toggle sort dropdown
    const [categoryData, setCategoryData] = useState(null)
    const {
        handleAddCart,
        selectedSize,
        setSelectedSize,
        selectedColor,
        setSelectedColor,
        setSelectedPrice,
        selectedImages,
        setSelectedImages,
        selectedProduct,
        setSelectedProduct,
    } = useCartContext();
    const fetchData = async () => {
        try {
            const response = await get('/product.php?action=fetch&admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&limit=10&page=1');
            console.log("menproduct", response.list);
            setMenProduct(response.list);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchCategory = async () => {
        try {
            const response = await get('/category.php?admin_token=OdRLLsX46k3L8EwZgpNlVK5tKiRaghecPNH&action=fetch&limit=10&page=1');
            console.log('Category Data', response.list)
            setCategoryData(response.list)
        } catch (error) {
            console.log('Error fetching data:', error)
        }
    }

    useEffect(() => {
        fetchData();
        fetchCategory();
    }, []);

    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const toggleSortDropdown = () => {
        setShowSortDropdown(!showSortDropdown);
    };

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };

    const handleQuickAdd = (product) => {
        setSelectedProduct(product);
        // Example action: add to cart
        // handleAddCart(product); // Assuming handleAddCart is a function to add product to cart
        // Example notification
        const defaultColor = product.colors[0];
        const defaultSize = defaultColor.size[0];
        setSelectedImages(defaultColor.image);
        console.log("first", defaultColor.image)
        setSelectedSize(defaultSize);
        setSelectedColor(defaultColor);
        setSelectedPrice(defaultColor.product_price);
    };

    return (
        <>
            <section className='men-product-banner-section'>
                <div className='men-product-banner-con'>
                    {/* Add your banner content here */}
                </div>
            </section>

            <section className='men-section-filter-con'>
                <div className='men-section-filert'>
                    <div className='men-section-main-filert'>
                        <div className='men-section-main-filert-details' onClick={showDrawer}>
                            <h2>Filter</h2>
                            <HiMenuAlt1 />
                        </div>
                    </div>
                    <div className='men-section-main-sort-by'>
                        <div className='men-section-main-filert-details2' onClick={toggleSortDropdown}>
                            <h2>Sort By</h2>
                            <MdOutlineKeyboardArrowDown />
                        </div>
                        <div className={`men-section-main-sort-by-dropdown ${showSortDropdown ? 'open' : ''}`}>
                            <button>Best seller</button>
                            <button>Price, low to high</button>
                            <button>Price, high to low</button>
                            <button>New arrivals</button>
                        </div>
                    </div>
                </div>
            </section>

            <section className='men-section-3-product-list-con'>
                <div className='row product-list-row'>
                    {/* {menproduct && menproduct.map((product, productIndex) => (
                        product.colors && product.colors.map((colorItem, colorIndex) => (
                            <div key={`${productIndex}-${colorIndex}`} className='col-lg-3 col-md-4 col-sm-6 product-list-col'>
                                <div className="product-card-main-con">
                                    <Link to={`${product.slug}`}>
                                        <div className="product-card-body-con">
                                            <div className="product-card-Image-con product-item__image double__image">
                                                <a>
                                                    {colorItem.image && (
                                                        <>
                                                            <div className="product-item__bg" style={{ backgroundImage: `url(${colorItem.image[0]?.images})` }}></div>
                                                            {colorItem.image[1] && (
                                                                <div className="product-item__bg__under" style={{ backgroundImage: `url(${colorItem.image[1]?.images})` }}></div>
                                                            )}
                                                        </>
                                                    )}
                                                </a>
                                                <div className="slideup is-not-relative">
                                                    <button
                                                        type="button"
                                                        className="caps"
                                                        data-focus-element=""
                                                        data-quick-add-label="1 Five-x-memeland-finger-tee"
                                                        data-collection-handle="primary"
                                                        tabIndex={0}
                                                        onClick={() => handleQuickAdd(product)}
                                                    >
                                                        <span>Quick add</span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="Product-card-details-con">
                                                <div className="Product-card-details">
                                                    <div className="product-card-product-name">
                                                        <h4>{product.product_name}</h4>
                                                    </div>
                                                    <div className="Product-card-product-review-price">
                                                        <h6>Rs. {colorItem.product_price ? colorItem.product_price.toString() : ''} /-</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))
                    ))} */}
                      {
                            MenData && MenData.map((product, productIndex) => (
                                console.log("product?.colors.images?.[0]?.images",product?.colors[0]?.product_price),
                                console.log("product",product?.colors[0]?.images[0].images),
                                // product.colors.map((colorItem, colorIndex) => (
                                    <div key={productIndex} className='col-lg-3 col-md-4 col-sm-6 product-list-col'>
                                        <div className="product-card-main-con">
                                            <Link to={`${product.slug}`}>
                                                <div className="product-card-body-con">
                                                <div className="product-card-Image-con product-item__image double__image new-product-item__bg">
                                                        <div className="product-item__bg">
                                                        <img src={product?.colors[0]?.images?.[0]?.images}/>
                                                        </div>
                                                        <div className="product-item__bg__under">
                                                        <img src={product?.colors[0]?.images?.[1]?.images}/>
                                                        </div>
                                                    </div>
                                                    <div className="Product-card-details-con">
                                                        <div className="Product-card-details">
                                                            <div className="product-card-product-name">
                                                                <h4>{product?.product_name}</h4>
                                                            </div>
                                                            {/* <div className="Product-card-product-review">
                                                                <div className="Product-card-product-review-star">
                                                                    <FaStar />
                                                                    <FaStar />
                                                                    <FaStar />
                                                                    <FaStar />
                                                                    <FaStar />
                                                                </div>
                                                                <div className="Product-card-product-review-text">
                                                                    <h5>1 Review</h5>
                                                                </div>
                                                            </div> */}
                                                            <div className="Product-card-product-review-price">
                                                                <h6>Rs. {product?.colors[0]?.product_price} /-</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            // ))
                        }
                </div>

            </section>

            <div className='Drawer-main-con'>
                <Drawer title="153 ITEMS" placement="left" onClose={onCloseDrawer} visible={openDrawer}>
                    <Collapse defaultActiveKey={['1', '2']} expandIconPosition="right">
                        <Panel header="Availability" key="1">
                            <div className='Drawer-Availability-details-con'>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>In stock</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>Out of stock</Checkbox>
                                </div>
                            </div>
                        </Panel>
                        <Panel header="Category" key="2">
                            <div className='Drawer-Availability-details-con'>
                                {
                                    categoryData && categoryData.map((elem) => {
                                        const { category_desc, category_img, category_name, category_slugs, gander } = elem
                                        return (
                                            <>
                                                <div className='Drawer-Availability-check-box'>
                                                    <Checkbox onChange={onChange}>{category_name}</Checkbox>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                                {/* <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>BLVCK02</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>Headwear</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>High-Tech</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>Hoodies & Sweaters</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>Lifestyle</Checkbox>
                                </div>
                                <div className='Drawer-Availability-check-box'>
                                    <Checkbox onChange={onChange}>Outerwear</Checkbox>
                                </div> */}
                            </div>
                        </Panel>
                    </Collapse>
                </Drawer>
            </div>

            <Footer />
        </>
    );
};

export default MenProduct;
