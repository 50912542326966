import Product06 from "../../Images/ecom/3/30013 copy.jpg";
import Product07 from "../../Images/ecom/3/30014 copy.jpg";
import Product08 from "../../Images/ecom/3/30015 copy.jpg";
import Product09 from "../../Images/ecom/3/30017 copy.jpg";
import Product20 from "../../Images/ecom/6/60035 copy.jpg";
import Product21 from "../../Images/ecom/6/60036 copy.jpg";
import Product22 from "../../Images/ecom/6/60037 copy.jpg";
import Product23 from "../../Images/ecom/6/60038 copy.jpg";
const WomenData = [
    {
        category_Id: "1",
        slug: "/Oversize-shirt-with-logo",
        product_Code: "1F - FHZLT001",
        product_Desc: "Eco-friendly stylish bag ideal for traveling, shopping, and office items like lunch box/laptop/water bottle. Can also be used for gifting purposes. It comes with an inner cloth lining with a titch button. No machine wash, spot clean to get rid of any stain. 100% Natural Jute & Cotton rope Dual color, Titch button, no zipper, Jute & White. ",
        product_Id: "1",
        product_name: "Oversize shirt with logo",
        action: "1",
        delivery_charges: "",
        product_type: "women",
        created_at: "2024-05-02 11:33:59",
        colors: [
            {
                color_id: "1",
                product_Id: "1",
                color_code: "#808080",
                color_name: "gray",
                product_price: "2715",
                product_qty: "10",
                size: [
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XS",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "S",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "M",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "L",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XL",
                    },
                ],
                images: [
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product06,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product07,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product08,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product09,
                        product_image_type: "new",
                    },
                ]
            },
        ],
    },
    {
        category_Id: "1",
        slug: "/Regular-oversize-with-center-logo",
        product_Code: "1F - FHZLT002",
        product_Desc: "Eco-friendly stylish bag ideal for traveling, shopping, and office items like lunch box/laptop/water bottle. Can also be used for gifting purposes. It comes with an inner cloth lining with a titch button. No machine wash, spot clean to get rid of any stain. 100% Natural Jute & Cotton rope Dual color, Titch button, no zipper, Jute & White. ",
        product_Id: "1",
        product_name: "Regular oversize with center logo",
        action: "1",
        delivery_charges: "",
        product_type: "women",
        created_at: "2024-05-02 11:33:59",
        colors: [
            {
                color_id: "1",
                product_Id: "1",
                color_code: "#808080",
                color_name: "gray",
                product_price: "2515",
                product_qty: "10",
                size: [
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XS",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "S",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "M",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "L",
                    },
                    {
                        product_color_id: "1",
                        size_Id: "1",
                        product_size: "XL",
                    },
                ],
                images: [
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product20,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product21,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product22,
                        product_image_type: "new",
                    },
                    {
                        product_color_id: "1",
                        product_images_id: "1",
                        images: Product23,
                        product_image_type: "new",
                    },
                ]
            },
        ],
    },
];

export default WomenData