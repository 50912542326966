import React from 'react';
import "../successandfailed/Successbtn.css";
import { Link } from 'react-router-dom';


const Successbtn = () => {
    return (
        <>
            <section className='btns-Checkout'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='Successbtn-con'>
                            <div className='Successbtn'>
                                <Link to="/order-success">
                                    <button>
                                        Success
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='Successbtn-con'>
                            <div className='Successbtn'>
                                <Link to="/order-failed">
                                <button>
                                    Failed
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Successbtn