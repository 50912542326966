import React, { useLayoutEffect } from 'react';
import "../Css/Home.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import HomeProduct from '../Component/ProductSlider/HomeProduct';
import Footer from '../Component/FooterComponent/Footer';
import { Link } from 'react-router-dom';

const Home = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    return (
        <>
            <section className='Home-Section-01'>
                <div className='Home-Section-Banner-main-con'>
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='Home-Banner-con-details-Image'>
                                <div className='Home-Banner-details-info-con'>
                                    <div className='Home-Banner-details-info'>
                                        <div className='Home-Banner-details-text-con'>
                                            <h3>Black Addiction</h3>
                                        </div>
                                        <div className='Home-Section-Btn-Main-con'>
                                            <div className='Home-Section-Btn-con'>
                                                <Link to="/women-product">
                                                    <button>
                                                        shop women
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className='Home-Section-Btn-con'>
                                                <Link to="/men-product">
                                                    <button>
                                                        shop men
                                                    </button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            demo
                        </SwiperSlide> */}
                    </Swiper>
                </div>
            </section>

            <HomeProduct />
            <Footer />
        </>
    )
}

export default Home